import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import ContentLoader from 'react-content-loader';

import Badge from '../../../Badge';
import HorizontalTextIconCard from '../../../HorizontalTextIconCard';
import WithLightTitle from '../../../WithLightTitle';
import AutoSuggest from '../AutoSuggest';
import { FETCH_STATUS } from '../RawSearchInput/RawSearchInput.types';

import styles from './AutoSuggestWithList.module.scss';
import { Props } from './AutoSuggestWithList.types';

const AutoSuggestWithList = <T extends { id: string; label: string; subLabel?: string }>({
  values,
  onChange,
  dataExtractor,
  title,
  globalFetchStatus,
  emptyText,
  singularTitle,
  isDisplayMode,
  cardContainerClassName,
  autosuggestTitle,
  results,
  onSearch,
  autosuggestFetchStatus,
  disabled,
  withSubLabel,
}: Props<T>) => {
  return (
    <>
      {globalFetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="5rem" width="100%" uniqueKey={title.replace(/ /g, '')}>
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
        </ContentLoader>
      )}
      {globalFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {!isDisplayMode && (
            <WithLightTitle
              title={autosuggestTitle}
              className={classnames(cardContainerClassName, styles.autosuggestContainer)}
            >
              <AutoSuggest
                disabled={disabled}
                id={title.replace(/ /g, '')}
                value={values}
                keyValueExtractor={(searchResult: T) => ({
                  key: searchResult.id,
                  value: searchResult.label ?? '',
                  subValue: searchResult.subLabel ?? '',
                })}
                onSearch={onSearch}
                onChange={items => {
                  onChange?.(items);
                }}
                searchResults={results}
                placeholder="1 caractère minimum"
                fetchStatus={autosuggestFetchStatus}
                withSubLabel={withSubLabel}
              />
            </WithLightTitle>
          )}
          {values.length ? (
            <WithLightTitle
              title={`${values.length} ${values.length > 1 ? title : singularTitle}`}
              className={cardContainerClassName}
            >
              {values.map(value => {
                return (
                  <HorizontalTextIconCard
                    key={value.id}
                    rightComponent={
                      dataExtractor(value).level ? (
                        <Badge value={dataExtractor(value).level?.toString() ?? ''} />
                      ) : undefined
                    }
                    leftIcon={dataExtractor(value).icon}
                    label={dataExtractor(value).label}
                    rightIcon={
                      !isDisplayMode && (
                        <Trashcan
                          onClick={() =>
                            onChange(values.filter(valueItem => valueItem.id !== value.id))
                          }
                        />
                      )
                    }
                    containerClassName={styles.card}
                  />
                );
              })}
            </WithLightTitle>
          ) : (
            <div className={styles.noValue}>{emptyText}</div>
          )}
        </>
      )}
    </>
  );
};

export default AutoSuggestWithList;
