import classnames from 'classnames';
import React from 'react';

import styles from './Table.module.scss';
import { Props } from './Table.types';

const Table = ({ headers, data, className }: Props) => {
  return (
    <table className={classnames(styles.container, className)}>
      <thead>
        <tr>
          {headers.map(label => (
            <th style={{ width: label.width }}>
              <div className={styles.header}>{label.label}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map(line => (
          <tr>
            {line.map(element => (
              <td>{element}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
