import { DragIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';

import styles from './HorizontalCardWithTitle.module.scss';
import { Props } from './HorizontalCardWithTitle.types';

const HorizontalCardWithTitle = ({
  title,
  subtitles,
  className,
  onClick,
  rightActions,
  leftIcon,
  classNameLeftIcon,
  leftSideClassName,
  titleClassname,
  subtitlesClassname,
  draggable,
}: Props) => (
  <div className={classnames(styles.container, className)} onClick={onClick}>
    <div style={{ flex: 1 }} className={classnames(styles.leftSide, leftSideClassName)}>
      {draggable && <DragIcon className={styles.dragIcon} />}
      {leftIcon && (
        <div className={classnames(styles.leftIcon, classNameLeftIcon)}> {leftIcon}</div>
      )}
      <div className={styles.leftTextContainer}>
        <div className={classnames(styles.title, titleClassname)}>{title}</div>
        {subtitles && (
          <div className={classnames(styles.description, subtitlesClassname)}>
            {subtitles?.map(subtitle => (
              <div>{subtitle}</div>
            ))}
          </div>
        )}
      </div>
    </div>
    {rightActions}
  </div>
);

export default HorizontalCardWithTitle;
