/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete, CircularProgress } from '@mui/material';
import { CloseOld, Loupe } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classnames from 'classnames';
import React, { useState } from 'react';

import TextInput from '../TextInput';
import VirtualOptionList, { VirtualOption } from '../VirtualOptionComponent';
import { VirtualGroup } from '../VirtualOptionComponent/VirtualOptionList.component';

import styles from './RawSearchInput.module.scss';
import { defaultProps, FETCH_STATUS, Props } from './RawSearchInput.types';

function RawSearchInput<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>({
  id,
  minLengthToSearch,
  disabled,
  placeholder,
  searchResults,
  keyValueExtractor,
  fetchStatus,
  disableClearable,
  onChange,
  multiple,
  useNativeOptionFiltering,
  showSelected,
  value,
  onSearch,
  renderTags,
  limitTags,
  className,
  groupBy,
  withSubLabel,
  noOptionsText,
  popupIcon,
}: Props<T, Multiple, DisableClearable>) {
  const [popUpIconDisplay, setpopUpIconDisplay] = useState('hidden');
  return (
    <Autocomplete
      id={id}
      options={searchResults}
      className={classnames(styles.container, className)}
      /* by default mui autocomplete will filter by itself results containing user input
      we sometimes want to disable this as the filtering may be done in an external 
      service (most often in the backend) */
      filterOptions={useNativeOptionFiltering === true ? undefined : option => option}
      freeSolo={false}
      openText="ouvrir"
      closeText="reset"
      loadingText={'recherche...'}
      value={value}
      noOptionsText={
        noOptionsText
          ? noOptionsText
          : fetchStatus === FETCH_STATUS.REJECTED
          ? 'référentiel absent'
          : 'pas de résultats'
      }
      loading={fetchStatus === FETCH_STATUS.PENDING}
      disablePortal
      getOptionLabel={option => keyValueExtractor(option).value}
      onInputChange={(_, value) => {
        onSearch?.(value.length >= minLengthToSearch ? value : '');
        if (value.length > 0) {
          setpopUpIconDisplay('none');
        } else {
          setpopUpIconDisplay('inline-flex');
        }
      }}
      multiple={multiple}
      ListboxComponent={VirtualOptionList}
      // little hack to hide selection when showSelected is false only
      renderTags={showSelected === false ? () => null : renderTags}
      limitTags={limitTags}
      popupIcon={popupIcon ?? <Loupe />}
      // Prevents the popup icon from turning upside down
      sx={{
        width: '100%',
        '& .MuiAutocomplete-popupIndicator': {
          transform: 'none',
          marginRight: '1rem',
          width: '1.5rem',
          display: popUpIconDisplay,
        },
        '& .MuiAutocomplete-clearIndicator': {
          marginRight: '1rem',
          width: '1.5rem',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 'solid 2px #2175d9', //main-blue-full
        },
      }}
      clearIcon={<CloseOld />}
      renderInput={props => {
        return (
          <TextInput
            {...props}
            placeholder={
              placeholder ??
              (minLengthToSearch > 0
                ? `indiquer ${minLengthToSearch} caractère${
                    minLengthToSearch > 1 ? 's' : ''
                  } minimum`
                : undefined)
            }
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <>
                  {fetchStatus === FETCH_STATUS.PENDING ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {props.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      disableClearable={disableClearable}
      onChange={(event, value, reason) => {
        if (!(reason === 'removeOption' && 'charCode' in event)) {
          // don't remove options when hitting the backspace key
          onChange(value);
        }
      }}
      disabled={disabled}
      renderOption={(props, option, { inputValue }) => {
        const label = keyValueExtractor(option).value;
        const subLabel = keyValueExtractor(option).subValue;
        const matches = match(label, inputValue);
        const parts = parse(label, matches);
        const { className, ...rest } = props;
        return (
          <VirtualOption
            key={label}
            liProps={{ key: label, ...rest }}
            parts={parts}
            subLabel={withSubLabel ? subLabel : undefined}
          />
        );
      }}
      clearOnBlur
      groupBy={groupBy}
      renderGroup={params => (
        <VirtualGroup key={params.key} group={params.group} children={params.children} />
      )}
    />
  );
}

RawSearchInput.defaultProps = defaultProps;

export default RawSearchInput;
