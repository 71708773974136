import { ListSubheader, Typography } from '@mui/material';
import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { VirtualOptionProps } from '../RawSearchInput/RawSearchInput.types';

import styles from './VirtualOptionList.module.scss';
import { Child } from './VirtualOptionList.types';

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={style}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  return (
    <Typography noWrap {...dataSet[0]} style={style}>
      {dataSet}[1]
    </Typography>
  );
}

export const VirtualOption = ({ liProps, parts, subLabel }: VirtualOptionProps) => (
  <li className={styles.option} {...liProps}>
    {parts.map((part, index) => (
      <span
        key={index}
        style={{
          fontWeight: part.highlight ? 700 : 400,
          whiteSpace: 'pre',
        }}
      >
        {part.text}
      </span>
    ))}
    {subLabel && (
      <span className={styles.optionSubLabel}>
        <br />
        {subLabel}
      </span>
    )}
  </li>
);

export const VirtualGroup = ({
  key,
  children,
  group,
}: {
  key: string;
  children: React.ReactNode;
  group: string;
}) => {
  return (
    <li key={key}>
      <div className={styles.groupHeader}>{group}</div>
      <ul className={styles.groupItems}>{children}</ul>
    </li>
  );
};

const VirtualOptionList = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: Child[] = [];
    (children as Child[]).forEach((item: Child & { children?: Child[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSingleLineSize = 40;
    const itemDoubleLineSize = 56;

    const getChildSize = (child: Child) => {
      if (Object.prototype.hasOwnProperty.call(child.props, 'group')) {
        return 48 + (child.props.children?.length ?? 0) * itemSingleLineSize;
      }
      return child.props.subLabel ? itemDoubleLineSize : itemSingleLineSize;
    };

    const getHeight = () => {
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref} {...other}>
        <VariableSizeList
          itemData={itemData}
          height={Math.min(250, getHeight())}
          width="100%"
          ref={gridRef}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </div>
    );
  }
);

export default VirtualOptionList;
