import { useEffect } from 'react';

export function useOutsideAlerter({
  ref,
  secondRef,
  action,
}: {
  ref: any;
  secondRef?: any;
  action: () => void;
}) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (secondRef.current === null ||
          (secondRef.current && !secondRef.current.contains(event.target)))
      ) {
        action();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action, secondRef]);
}
